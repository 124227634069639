import intlTelInput from 'intl-tel-input';
import { type ForwardedRef, forwardRef, useCallback, useRef } from 'react';
import useEvent from 'react-use-event-hook';
import { useCallbackRef } from '@/hooks/useCallbackRef';
import { Input } from '@/ui';
import 'intl-tel-input/build/css/intlTelInput.css';
import type { TelephoneInputProps } from './types';

const Component = (
  { itiInstanceRef, dropdownContainer, ...props }: TelephoneInputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const hadFocusRef = useRef(false);

  // `useEvent` is used to ensure `assignRefProp` is stable while also allowing
  // the function to access the latest value of `ref` which is unstable.
  // `ref` is unstable due to this issue:
  // https://github.com/orgs/react-hook-form/discussions/10549
  //
  // If `ref` were issued as a dependency of `useCallback` then it would cause
  // the iti instance to be recreated every time the parent form is submitted
  // or revalidated, and that causes issues with the user's input.
  const assignRefProp = useEvent((input: HTMLInputElement | null) => {
    if (typeof ref === 'function') {
      ref(input);
    } else if (ref) {
      ref.current = input;
    }
  });

  const phoneNumberRef = useCallbackRef<HTMLInputElement>(
    useCallback(
      (node) => {
        if (node && !itiInstanceRef.current) {
          assignRefProp(node);

          itiInstanceRef.current = intlTelInput(node, {
            dropdownContainer,
            allowDropdown: false,
            initialCountry: 'us',
            // @ts-expect-error Importing utils this way works but the package isn't in the types definition
            loadUtils: () => import('intl-tel-input/utils'),
          });

          // Restore focus if the input had focus before and it was just re-created
          if (node && hadFocusRef.current) {
            node.focus();
          }
        }

        return () => {
          if (itiInstanceRef.current) {
            hadFocusRef.current = node === document.activeElement;
            itiInstanceRef.current.destroy();
            itiInstanceRef.current = null;
            assignRefProp(null);
          }
        };
      },
      [itiInstanceRef, dropdownContainer, assignRefProp]
    )
  );

  return <Input ref={phoneNumberRef} type="text" {...props} />;
};

const TelephoneInput = forwardRef<HTMLInputElement, TelephoneInputProps>(
  Component
);

export default TelephoneInput;
